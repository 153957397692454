export default class AppSettings {
    public static clientId: string = "fcdf90d9-7098-4d26-8ecc-ba4aec1778d3";
    public static tenant: string = "7d27523a-c3a6-40fd-b0f5-f24b85009224"; //7d27523a-c3a6-40fd-b0f5-f24b85009224
    // this is WebAPI URL for local development
    public static apiRoot: string = "https://fmtbisweb-webapi.azurewebsites.net/api/";
    //public static apiRoot: string = "https://localhost:44302/api/";
    public static apiScopes: string[] = [
        "api://" + AppSettings.clientId + "/Reports.Embed",
        "openid",
        "email"
  ];
}                           