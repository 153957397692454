import 'bootstrap';
import * as $ from 'jquery';
import * as powerbi from "powerbi-client";
import * as pbimodels from "powerbi-models";
require('powerbi-models');
require('powerbi-client');
import SpaAuthService from './services/SpaAuthService';
import AppOwnsDataWebApi from './services/AppOwnsDataWebApi'


import { Report, Dataset, ViewModel, ActivityLogEntry, FilterObject } from './models/models';
/*import { log } from 'util';*/
import { each } from 'jquery';

export default class App {
    // fields for UI elemenets in DOM
    private static toogleMenu: JQuery;
    private static testEnvNotice: JQuery;
    private static document: any;
    private static mainBody: JQuery;
    private static topBanner: JQuery;
    private static userGreeting: JQuery;
    private static login: JQuery;
    private static logout: JQuery;
    private static viewAnonymous: JQuery;
    private static viewUnassigned: JQuery;
    private static loadingSpinner: JQuery;
    private static loadingSpinnerMessage: JQuery;
    private static viewAuthenticated: JQuery;
    private static viewAuthenticatedByClass: JQuery;
    private static viewAuthenticatedHeader: JQuery;
    private static tenantName: JQuery;
    private static reportsList: JQuery;
    private static datasetsList: JQuery;
    private static datasetsListContainer: JQuery;
    private static embedToolbar: JQuery;
    private static breadcrumb: JQuery;
    private static toggleEditButton: JQuery;
    private static fullScreenButton: JQuery;
    private static embedContainer: JQuery;
    private static currentDashboard: powerbi.Dashboard;
    private static resizedFinished: any;
    private static currentReport: powerbi.Report;
    private static layoutMode: "master" | "mobile";
    private static breakPointWidth: number = 576;
    private static reportsAndCategories: JQuery;

    private static powerbi: powerbi.service.Service = window.powerbi;
    private static viewModel: ViewModel;
    private static newEmbedToken: pbimodels.TokenType.Aad;

    private static FilterContext: pbimodels.IBasicFilter[] = [];

    //private static table;
    //private static column;
    //private static selectedItems: string[];
    private static reportId;
    private static setPage;
    public static onDocumentReady = () => {

        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);

        App.reportId = urlParams.get('currentSelectedReportId');
        App.setPage = urlParams.get('setPage');

        try {
            let filterobjects = urlParams.getAll('filterObject');

            for (var i = 0; i < filterobjects.length; i++) {
                let fo = <FilterObject>JSON.parse(filterobjects[i]);
                console.log(fo);
                App.FilterContext.push(
                    {
                        $schema: 'http://powerbi.com/product/schema#basic',
                        target: {
                            table: fo.table,
                            column: fo.column
                        },
                        operator: <pbimodels.BasicFilterOperators>fo.operator,
                        values: fo.value,
                        filterType: pbimodels.FilterType.Basic
                    }
                )
            }
        } catch (e) {
            console.log(e)
        }

        // set search property to blank
        urlParams = null;
        queryString = null;
        var newURL = location.href.split("?")[0];
        window.history.pushState('object', document.title, newURL);

        //const year = urlParams.get('year');
        //const month = urlParams.get('month');
        //const day = urlParams.get('day');

        // initialize fields for UI elemenets 
        App.toogleMenu = $('.toggle-menu');
        App.testEnvNotice = $("#test-environment-notice");
        App.document = $("document");
        App.mainBody = $("#main-body");
        App.topBanner = $("#top-banner");
        App.userGreeting = $("#user-greeting");
        App.login = $("#login");
        App.logout = $("#logout");
        App.viewAnonymous = $("#view-anonymous");
        App.viewUnassigned = $("#view-unassigned");
        App.loadingSpinner = $("#view-loading-spinner");
        App.loadingSpinnerMessage = $("#spinner-message");
        App.viewAuthenticated = $("#view-authenticated");
        App.viewAuthenticatedByClass = $(".view-authenticated-by-class");
        App.viewAuthenticatedHeader = $("#view-authenticated-header");
        App.tenantName = $("#tenant-name");
        App.reportsList = $("#reports-list");
        App.reportsAndCategories = $("#reportsAndCategories");
        App.datasetsList = $("#datasets-list");
        App.datasetsListContainer = $("#datasets-list-container");
        App.embedToolbar = $("#embed-toolbar");
        App.breadcrumb = $("#breadcrumb");
        App.toggleEditButton = $("#toggle-edit");
        App.fullScreenButton = $("#full-screen");
        App.embedContainer = $("#embed-container");
        // set up authentication callback
        SpaAuthService.uiUpdateCallback = App.onAuthenticationCompleted;


        App.login.on("click", async () => {
            await SpaAuthService.login();
        });

        App.logout.on("click", () => {
            SpaAuthService.logout();
            App.refreshUi();
        });

        // Comment out to disable auto-authentication on startup
        SpaAuthService.attemptSillentLogin();

        App.refreshUi();

        App.registerWindowResizeHandler();



    }

    private static refreshUi = () => {

        if (SpaAuthService.userIsAuthenticated) {
            App.userGreeting.text("Velkommen " + SpaAuthService.userDisplayName);
            App.userGreeting.prop('title', 'Email: ' + SpaAuthService.userName);
            App.tenantName.show();
            App.login.hide();
            App.logout.show();
            App.viewAnonymous.hide();
        }
        else {
            App.userGreeting.text("");
            App.tenantName.hide();
            App.login.show();
            App.logout.hide();
            App.viewAnonymous.show();
            App.viewAuthenticated.hide();
            App.viewAuthenticatedByClass.hide();
        }
    }

    private static onAuthenticationCompleted = async () => {
        App.loadingSpinnerMessage.text("Autentificerer bruger ...");
        App.loadingSpinner.show(250);
        App.viewAnonymous.hide();
        await AppOwnsDataWebApi.LoginUser(SpaAuthService.userName, SpaAuthService.userDisplayName);
        App.loadingSpinner.hide();
        App.refreshUi();
        App.initializeAppData();
    }



    private static initializeAppData = async () => {

        App.loadingSpinnerMessage.text("Henter rapporter");
        App.loadingSpinner.show();
        App.viewAnonymous.hide();

        App.viewModel = await AppOwnsDataWebApi.GetEmbeddingData();

        if (App.viewModel.tenantName == "") {
            App.viewAnonymous.hide();
            App.viewAuthenticated.hide();
            App.viewAuthenticatedByClass.hide();
            App.loadingSpinner.hide();
            App.viewUnassigned.show(500);
        }
        else if (App.reportId != null) {
            App.loadViewModel(App.viewModel, App.reportId, App.FilterContext);
        }
        else {
            App.loadViewModel(App.viewModel);
        }

        window.setInterval(App.reportOnExpiration, 1800000);
        window.setInterval(App.refreshCurrentReport, 1800000);
    }

    private static loadViewModel = async (viewModel: ViewModel, reportId?: string, filter?: pbimodels.IBasicFilter[]) => {

        App.viewAuthenticated.hide();
        App.viewAuthenticatedByClass.hide();
        App.viewUnassigned.hide();

        App.powerbi.reset(App.embedContainer[0]);

        App.tenantName.text(viewModel.tenantName);
        App.reportsList = App.reportsList.empty();
        App.datasetsList = App.datasetsList.empty();

        if (viewModel.reports.length == 0) {
            App.reportsList.append($("<li>")
                .text("Ingen rapporter i arbejdsområdet!")
                .addClass("no-content"));
        }
        else {


            //        var reportSelector = `


            //                <div class="col-lg-3 col-sm-6">
            //                    <a class="portfolio-box" href="" title="Project Name" data-toggle="modal" data-target="#energy">
            //                        <img class="img-fluid" src="/img/badevand1.png" alt="..." />
            //                        <div class="portfolio-box-caption">
            //                            <div class="project-category text-white-50">#Health #Paris agreement</div>
            //                            <div class="project-name">Wastewater - overflow warning</div>

            //                        </div>
            //                    </a>
            //                </div>
            //`;

            //var collg = $("<li>").addClass("");
            //var a = $("<a>", { "href": "#" })
            //.text(report.name)
            //    .click(() => { App.embedDashboard() })
            //    .html("<i class='fa fa - home'></i> Dash"); 
            //.attr("data-toggle", "modal")
            //.attr("data-target", report.name);


            //collg.append(a);

            //App.reportsList.append(collg);
            let descrCollection: string[] = ["Ukategoriset"];
            viewModel.reports.forEach((report: Report) => {

                var extractedCategoryFromReportName = App.extractSegmentInSquareBrackets(report.name);

                if (descrCollection.includes(extractedCategoryFromReportName)) {
                    const existingValue = extractedCategoryFromReportName;
                    console.log(`${existingValue} exists in the array.`);
                } else {
                    console.log(`${extractedCategoryFromReportName} does not exist in the array. Adding it.`);
                    descrCollection.push(extractedCategoryFromReportName);
                }

                console.log(descrCollection);

                var collg = $("<li>").addClass("");
                var a = $("<a>", { "href": "#" })
                    .addClass("link-dark rounded")
                    .attr("data-bs-toggle", "collapse")
                    .attr("data-bs-target", "#menuContainer")
                    //.text(report.name)
                    .on("click", () => { App.embedReport(report) })

                    .html('<i class="bi bi-file-bar-graph" style="padding-right:10px"> </i> ' + report.name);

                //.html("<i class='fa fa - home'></i>" + report.name);

                //.attr("data-toggle", "modal")
                //.attr("data-target", report.name);


                collg.append(a);

                App.reportsList.append(collg);

                //var collg = $("<div>").addClass("col-lg-2 col-sm-3");
                //var a = $("<a>", { "href": "javascript:void(0);" })
                //    //.text(report.name)
                //    .click(() => { App.embedReport(report) })
                //    .addClass("portfolio-box")
                //    .attr("title", "Projekt name")
                //    .attr("data-toggle", "modal")
                //    .attr("data-toggle", "modal")
                //    .attr("data-target", "#energy");
                //var img = $("<img>")
                //    .addClass("img-fluid")
                //    .attr("src", "/img/" + report.name + ".png")
                //    .attr("onerror", "this.onerror=null; this.src='/img/default.png'");
                //var portfolioboxcaption = $("<div>").addClass("portfolio-box-caption");
                //var projectcategory = $("<div>").addClass("project-category text-white-50").text("Report");
                //var projectname = $("<div>").addClass("project-name").text(report.name);
                //collg.append(a);
                //a.append(img);
                //a.append(portfolioboxcaption);
                //portfolioboxcaption.append(projectcategory);
                //portfolioboxcaption.append(projectname);
                //App.reportsList.append(collg);



                //divContainer.append("<div>").addClass("container-fluid p-0")
                //    .append("<div>").addClass("row g-0")
                //    .append("<div>").addClass("col-lg-2 col-sm-4")
                //    .append($("<a>", {
                //        "href": "javascript:void(0);"
                //    }).text(report.name).click(() => { App.embedReport(report) })).addClass("portfolio-box")
                //    .append("<img>").attr("src", "/img/elfront.png").addClass("img-fluid");
                //$("#portfolio-box").append("<div>").addClass("project-category text-white-50").text("#ParisAgreement #Climate #CO2 reduction")
                //App.reportsList.append(divContainer);

                //  var li = $("<li>");
                //  //li.append($("<i>").addClass("fa fa-bar-chart"));
                //  li.append($("<a>", {
                //    "href": "javascript:void(0);"
                //  }).text(report.name).click(() => { App.embedReport(report) }));
                //      App.reportsList.append(li2);
            });

        }

        if (viewModel.userCanCreate) {
            if (viewModel.datasets.length == 0) {
                App.datasetsList.append($("<li>")
                    .text("no datasets in workspace")
                    .addClass("no-content"));
            }
            else {
                viewModel.datasets.forEach((dataset: Dataset) => {
                    var li = $("<li>");
                    //li.append($("<i>").addClass("fa fa-database"));
                    li.append($("<a>", {
                        "href": "javascript:void(0);"
                    }).text(dataset.name).on("click", () => { App.embedNewReport(dataset) }));
                    App.datasetsList.append(li);
                });
            }
        }

        App.loadingSpinner.hide();
        App.viewAuthenticated.show();
        App.viewAuthenticatedByClass.show();

        if (reportId !== undefined) {
            var newReport: Report = viewModel.reports.find((report) => report.id === reportId);

            App.embedReport(newReport, false, filter); //Sat til false 
        }
        else {
            var newReport: Report = viewModel.reports[0];
            App.embedReport(newReport, false);
        }

    }

    //private static embedDashboard = async () => {
    //    App.powerbi.reset(App.embedContainer[0]);
    //    var models = pbimodels;
    //    //Embed dashboard to <div> dashboard-container
    //    var dashboardConfig: powerbi.IDashboardEmbedConfiguration = {
    //        accessToken: App.viewModel.dashboardtoken,
    //        embedUrl: "https://app.powerbi.com/dashboardEmbed?dashboardId=57a6e169-e71d-4a7c-88cc-a6dd70ca2512&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOmZhbHNlfX0%3d",
    //        id: "57a6e169-e71d-4a7c-88cc-a6dd70ca2512",
    //        pageView: 'fitToWidth',
    //        tokenType: models.TokenType.Embed,
    //        type: 'dashboard'

    //    }
    //    App.currentDashboard = <powerbi.Dashboard>App.powerbi.embed(App.embedContainer[0], dashboardConfig);
    //}

    //private static getNewAccessToken = async function () {
    //    // Code you need to add for generating new Azure AD token

    //        return null;
    //};

    private static embedReport = async (report: Report, editMode: boolean = false, filter?: pbimodels.IBasicFilter[]) => {


        App.setReportLayout();

        var models = pbimodels;

        var permissions;

        if (App.viewModel.userCanEdit && App.viewModel.userCanCreate) {
            permissions = models.Permissions.All;
        }
        else if (App.viewModel.userCanEdit && !App.viewModel.userCanCreate) {
            permissions = models.Permissions.ReadWrite;
        }
        else if (!App.viewModel.userCanEdit && App.viewModel.userCanCreate) {
            permissions = models.Permissions.Copy;
        }
        else if (!App.viewModel.userCanEdit && !App.viewModel.userCanCreate) {
            permissions = models.Permissions.Read;
        }

        App.setLayoutMode();
        var layoutMode: pbimodels.LayoutType =
            App.layoutMode == "master" ?
                models.LayoutType.Master :
                models.LayoutType.MobilePortrait;


        var config: powerbi.IEmbedConfiguration = {

            type: 'report',
            id: report.id,
            embedUrl: report.embedUrl,
            accessToken: App.viewModel.embedToken,
            tokenType: models.TokenType.Embed,
            permissions: permissions,
            filters: App.FilterContext, //Filter objekter kommer ind fra url parametre.
            viewMode: editMode ? models.ViewMode.Edit : models.ViewMode.View,
            settings: {
                personalBookmarksEnabled: true,
                localeSettings: {
                    language: "da-DK"
                },
                layoutType: layoutMode,
                background: models.BackgroundType.Default,
                panes: {
                    bookmarks: {
                        visible: false
                    },
                    filters: { visible: false },
                    pageNavigation: { visible: true, position: models.PageNavigationPosition.Left }
                }
            }
        };


        App.powerbi.reset(App.embedContainer[0]);

        var timerStart: number = Date.now();
        var initialLoadComplete: boolean = false;
        var loadDuration: number;
        var renderDuration: number;
        App.currentReport = <powerbi.Report>App.powerbi.embed(App.embedContainer[0], config);

        App.currentReport.off("pageChanged");

        App.currentReport.on("pageChanged", async (event: any) => {
            const page = event.detail.newPage;
        });

        App.currentReport.off("loaded")
        App.currentReport.on("loaded", async (event: any) => {

            loadDuration = Date.now() - timerStart;
            App.setReportLayout();

            App.currentReport.getPages().then(
                function (pages) {
                    if (App.setPage != undefined) {
                        App.currentReport.setPage(App.setPage);
                    }
                    return;
                });
        });

        App.currentReport.off("rendered");
        App.currentReport.on("rendered", async (event: any) => {

            if (!initialLoadComplete) {
                renderDuration = Date.now() - timerStart;
                var correlationId: string = await App.currentReport.getCorrelationId();
                await App.logViewReportActivity(correlationId, App.viewModel.embedTokenId, report, loadDuration, renderDuration);
                initialLoadComplete = true;
            }

        });

        App.currentReport.off("saved");
        App.currentReport.on("saved", async (event: any) => {
            if (event.detail.saveAs) {
                console.log("SaveAs Event", event);
                var orginalReportId = report.id;
                var reportId: string = event.detail.reportObjectId;
                var reportName: string = event.detail.reportName;
                await App.logCopyReportActivity(report, reportId, reportName, App.viewModel.embedTokenId);
                App.viewModel = await AppOwnsDataWebApi.GetEmbeddingData();
                App.loadViewModel(App.viewModel, reportId);
            }
            else {
                console.log("Save Event", event);
                await App.logEditReportActivity(report, App.viewModel.embedTokenId);
            }
        });

        var viewMode = editMode ? "edit" : "view";

        App.breadcrumb.text("Reports > " + report.name);

        if (!App.viewModel.userCanEdit && !App.viewModel.userCanCreate) {
            console.log("Hiding toggle edit");
            App.toggleEditButton.hide();
        }
        else {
            App.toggleEditButton.show();
            App.toggleEditButton.on("click", () => {
                // toggle between view and edit mode
                viewMode = (viewMode == "view") ? "edit" : "view";
                App.currentReport.switchMode(viewMode);
                // show filter pane when entering edit mode
                var showFilterPane = (viewMode == "edit");
                App.currentReport.updateSettings({
                    panes: {
                        filters: { visible: showFilterPane, expanded: false }
                    }
                });
            });
        }


        App.fullScreenButton.on("click", () => {
            App.currentReport.fullscreen();
        });
        /*
        App.document.on("onVisibilityChange", (event: VisibilityState) => {
            // Check the access token when the tab is visible
            if (!App.document.hidden) {
                App.checkTokenAndUpdate()
            }
        });
        */
    }




    //const basicFilter: pbimodels.IBasicFilter = {
    //    $schema: "http://powerbi.com/product/schema#basic",
    //    target: {
    //        table: App.table,
    //        column: App.column
    //    },
    //    operator: "In",
    //    values: [1, 2, 3, 4],
    //    filterType: pbimodels.FilterType.Basic,
    //    requireSingleSelection: true
    //}

    private static embedNewReport = (dataset: Dataset) => {


        var models = pbimodels;

        var config: powerbi.IEmbedConfiguration = {
            datasetId: dataset.id,
            embedUrl: "https://app.powerbi.com/reportEmbed",
            accessToken: App.viewModel.embedToken,
            tokenType: models.TokenType.Embed,
            settings: {
                panes: {
                    filters: { visible: true, expanded: false }
                }
            }
        };


        // Embed the report and display it within the div container.
        App.powerbi.reset(App.embedContainer[0]);
        var embeddedReport = App.powerbi.createReport(App.embedContainer[0], config);


        ////
        //var collg = $("<li>");
        //var a = $("<a>", { "href": "#" })
        //    .on("click", () => {
        //        embeddedReport.fullscreen();
        //    })
        //    .html("<i class='fa fa-home'></i> asdasd");

        //collg.append(a);
        //App.reportsList.append(collg)
        ////

        $("#breadcrumb").text("Datasets > " + dataset.name + " > New Report");
        $("#embed-toolbar").show();

        $("#toggle-edit").hide();
        $("#full-screen").off("click");
        $("#full-screen").on("click", () => {
            embeddedReport.fullscreen();
        });

        // handle save action on new report
        embeddedReport.on("saved", async (event: any) => {
            console.log("Create Report Event", event);
            var reportId: string = event.detail.reportObjectId;
            var reportName: string = event.detail.reportName;
            await App.logCreateReportActivity(dataset, reportId, reportName, App.viewModel.embedTokenId);
            App.viewModel = await AppOwnsDataWebApi.GetEmbeddingData();
            App.loadViewModel(App.viewModel, reportId);
        });

    }

    private static setLayoutMode = () => {
        let useMobileLayout: boolean = (App.mainBody.width() < App.breakPointWidth);
        App.layoutMode = useMobileLayout ? "mobile" : "master";
    };


    private static setReportLayout = async () => {

        let useMobileLayout: boolean = (App.mainBody.width() < App.breakPointWidth);
        // check to see if layout mode switches between master and mobile
        if ((useMobileLayout && App.layoutMode == "master") ||
            (!useMobileLayout && App.layoutMode == "mobile")) {
            if (App.currentReport) {
                console.log("switching layout mode...")
                App.layoutMode = useMobileLayout ? "mobile" : "master";
                let sameReport: Report = App.viewModel.reports.find(async (report) => report.id === await App.currentReport.getId());
                App.embedReport(sameReport);
            }
        }
        else {
            var models = pbimodels;
            if (useMobileLayout) {
                App.tenantName.hide();
                App.toggleEditButton.hide();
                App.fullScreenButton.hide();
                App.datasetsListContainer.hide();
                $(App.embedContainer).height($(App.embedContainer).width() * 3);
            }
            else {
                // set to landscape for full browser view
                App.tenantName.show();
                App.fullScreenButton.show();
                if (App.viewModel && App.viewModel.userCanCreate) {
                    App.datasetsListContainer.show();
                }
                else {
                    App.datasetsListContainer.hide();
                }
                let availableHeight: number = $(window).height() - (App.topBanner.height() + App.viewAuthenticatedHeader.height()) - 8;
                let heightFromWidth = $(App.embedContainer).width() * (9 / 16);
                let height = Math.min(availableHeight, heightFromWidth);
                $(App.embedContainer).height(height);
            }
        }

    };

    private static logViewReportActivity = async (correlationId: string, embedTokenId: string, report: Report, loadDuration: number, renderDuration) => {
        var logEntry: ActivityLogEntry = new ActivityLogEntry();
        logEntry.CorrelationId = correlationId;
        logEntry.EmbedTokenId = embedTokenId;
        logEntry.Activity = "ViewReport";
        logEntry.LoginId = App.viewModel.user;
        logEntry.Tenant = App.viewModel.tenantName;
        logEntry.Report = report.name;
        logEntry.ReportId = report.id;
        logEntry.DatasetId = report.datasetId;
        logEntry.Dataset = (App.viewModel.datasets.find((dataset) => dataset.id === report.datasetId)).name;
        logEntry.LoadDuration = loadDuration;
        logEntry.RenderDuration = renderDuration;
        await AppOwnsDataWebApi.LogActivity(logEntry);
    };

    private static logEditReportActivity = async (report: Report, embedTokenId: string) => {
        var logEntry: ActivityLogEntry = new ActivityLogEntry();
        logEntry.CorrelationId = "";
        logEntry.Activity = "EditReport";
        logEntry.LoginId = App.viewModel.user;
        logEntry.Tenant = App.viewModel.tenantName;
        logEntry.Report = report.name;
        logEntry.ReportId = report.id;
        logEntry.DatasetId = report.datasetId;
        logEntry.EmbedTokenId = embedTokenId;
        logEntry.Dataset = (App.viewModel.datasets.find((dataset) => dataset.id === report.datasetId)).name;
        await AppOwnsDataWebApi.LogActivity(logEntry);
    };

    private static logCopyReportActivity = async (orginalReport: Report, reportId: string, reportName, embedTokenId: string) => {
        var logEntry: ActivityLogEntry = new ActivityLogEntry();
        logEntry.Activity = "CopyReport";
        logEntry.LoginId = App.viewModel.user;
        logEntry.Tenant = App.viewModel.tenantName;
        logEntry.Report = reportName;
        logEntry.ReportId = reportId;
        logEntry.OriginalReportId = orginalReport.id;
        logEntry.DatasetId = orginalReport.datasetId;
        logEntry.EmbedTokenId = embedTokenId;
        logEntry.Dataset = (App.viewModel.datasets.find((dataset) => dataset.id === orginalReport.datasetId)).name;
        await AppOwnsDataWebApi.LogActivity(logEntry);
    };

    private static logCreateReportActivity = async (dataset: Dataset, reportId: string, reportName, embedTokenId: string) => {
        var logEntry: ActivityLogEntry = new ActivityLogEntry();
        logEntry.Activity = "CreateReport";
        logEntry.LoginId = App.viewModel.user;
        logEntry.Tenant = App.viewModel.tenantName;
        logEntry.Report = reportName;
        logEntry.ReportId = reportId;
        logEntry.DatasetId = dataset.id;
        logEntry.Dataset = dataset.name;
        logEntry.EmbedTokenId = embedTokenId;
        await AppOwnsDataWebApi.LogActivity(logEntry);
    };

    private static registerWindowResizeHandler = async () => {
        $(window).on("resize", async function () {
            clearTimeout(App.resizedFinished);
            App.resizedFinished = setTimeout(async function () {
                App.setReportLayout();
            }, 100);
        });
    }
    private static refreshDMIContainer = async () => {
        App.currentReport.refresh();
        /*this.currentReport.refresh();*/
    }

    private static refreshCurrentReport = async () => {
        App.currentReport.refresh();
        /*this.currentReport.refresh();*/
    }
    private static reportOnExpiration = async () => {
        var secondsToExpire = Math.floor((new Date(App.viewModel.embedTokenExpiration).getTime() - new Date().getTime()) / 1000);
        var minutes = Math.floor(secondsToExpire / 60);
        var seconds = secondsToExpire % 60;
        var timeToExpire = minutes + ":" + seconds;
        console.log("Token expires in ", timeToExpire);
        if (minutes < 59) {
            //Refresh EmbedToken every 30 minutes
            AppOwnsDataWebApi.GetEmbedToken().then((embedToken: any) => {

                App.viewModel.embedToken = embedToken.embedToken;
                //App.currentReport.setAccessToken(embedToken.embedToken);
                App.viewModel.embedTokenExpiration = new Date().toISOString().toString();
            });

        }
    }
    private static getParameterByName(name: string, url?: string): string | null {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    private static refreshIframe(): void {
        // Get the 'r' parameter from the URL
        const reportParameter: string | null = this.getParameterByName('r');

        // Set the src attribute of the iframe with the dynamic parameter
        const iframe: HTMLIFrameElement | null = document.getElementById('powerBiFrame') as HTMLIFrameElement;

        if (reportParameter && iframe) {
            iframe.src = `https://app.powerbi.com/view?r=${reportParameter}`;
        }
    }
    private static extractSegmentInSquareBrackets(input: string): string | null {
        const regex = /\[(.*?)\]/;
        const match = input.match(regex);

        return match ? match[1] : null;
    }
}

$(App.onDocumentReady);